<template>
  <div class="p-3 inline-block align-middle">
    <div
      id="tile"
      class="cursor-pointer p-4 bg-white inline-block w-72 rounded-xl relative h-32"
      @click="openChat"
    >
      <div>
        <span class="text-lg font-medium leading-none">{{ chat.partner }}</span>
        <div
          class="max-h-96 bg-primary/10 w-full overflow-y-auto rounded-lg p-2"
          :class="chat.lastMsg.incoming ? 'text-left' : 'text-right'"
          ref="chat"
        >
          <div
            class="inline-block bg-white rounded-lg px-2 py-1 text-left"
            :class="chat.lastMsg.incoming ? 'bg-primary/20' : 'bg-white'"
          >
            <span class="text-sm"
              >{{ chat.lastMsg.author }}, {{ chat.lastMsg.time }}</span
            >
            <p class="text-black whitespace-pre">{{ chat.lastMsg.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ChatTile",
  props: {
    chat: Object,
    context: String,
  },
  data() {
    return {};
  },
  methods: {
    openChat() {
      if (this.context == "business")
        this.$router.push("/b/board?c=" + this.chat.applicationId);
      else if(this.context == "private"){
        this.$router.push("/c/overview?c=" + this.chat.applicationId);
      }
    },
  },
};
</script>