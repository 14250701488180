<template >
  <div
    ref="modalbg"
    class="z-30 center fixed text-center w-full h-full sm:pt-32 sm:p-4 bg-black/50"
    @click="checkClosing($event)"
  >
    <div
      class="bg-white inline-block w-full lg:w-1/2 h-full sm:h-auto sm:rounded-3xl overflow-y-auto p-5 basis-0 relative"
    >
      <div @click="$emit('close')" class="iconborder-modal z-40">
        <font-awesome-icon size="lg" icon="xmark" class="inborder" />
      </div>
      <span class="w-full text-center font-bold text-2xl">Nachrichten</span>
      <div class="w-full text-center">
        <span class="font-bold">{{ partner }}</span>
        <span class="text-sm ml-2 underline cursor-pointer" @click="$emit('openApplication')">(Öffnen)</span>
      </div>
      <div class="text-left">
        <span class="w-full text-left pt-5"
          ><b>Stelle:</b> {{ application.jobtitle }}</span
        >
      </div>
      <div
        class="h-96 bg-primary/10 w-full overflow-y-auto rounded-lg p-2"
        ref="chat"
      >
        <div
          v-for="message in messages"
          :key="message"
          class="pb-2"
          :class="message.incoming ? 'text-left' : 'text-right'"
        >
          <div
            class="inline-block bg-white rounded-lg px-2 py-1 text-left"
            :class="message.incoming ? 'bg-primary/20' : 'bg-white'"
          >
            <span class="text-sm"
              >{{ message.author }}, {{ message.time }}</span
            >
            <p class="text-black whitespace-pre">{{ message.message }}</p>
          </div>
        </div>
      </div>
      <div class="border-t-2 pt-2">
        <textarea
          v-model="newMessage"
          placeholder="Schreiben Sie hier Ihre Nachricht..."
          class="w-full resize-none border p-2"
        ></textarea>
        <span class="text-red-500"
          >Der Chat-Partner wird per E-Mail über die Nachricht informiert.</span
        >
        <div class="w-full text-right">
          <button @click="addMessage()">Senden</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessAPI from "@/API/Business";
import PrivateAPI from "@/API/Private";
export default {
  props: ["application", "context"],
  emits: ["close","openApplication"],
  data() {
    return {
      partner: "",
      newMessage: "",
      API: BusinessAPI,
      messages: [],
    };
  },
  methods: {
    getMessages() {
      this.API.getChatMessages(this.application.id, (s) => {
        this.messages = s;
        setTimeout(() => {
        this.$refs["chat"].scrollTop = this.$refs["chat"].scrollHeight;
      }, 100);
      });
    },
    addMessage() {
      if(this.newMessage.trim()==""){
        return;
      }
      this.API.addChatMessage(
        this.application.id,
        { message: this.newMessage },
        
        (s) => {
          this.getMessages();
        }
      );
      this.newMessage = "";
      setTimeout(() => {
        this.$refs["chat"].scrollTop = this.$refs["chat"].scrollHeight;
      }, 100);
    },
    checkClosing(e) {
      if (e.target === this.$refs.modalbg) {
        this.$emit("close");
      }
    },
  },
  mounted() {
    window.vm = this;
    if (this.context == "private") {
      this.API = PrivateAPI;
      this.partner = this.application.companyName;
    } else {
      this.partner = this.application.name;
    }
    this.getMessages();
    setInterval(() => {
      this.getMessages();
    }, 10000);
  },
};
</script>

<style>
</style>