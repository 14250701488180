import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import './index.css'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faAddressCard, faArchive, faArrowUpRightFromSquare, faShareFromSquare,faArrowsUpToLine, faBars, faCalendarCheck, faCalendarDays, faCheckCircle, faCoins, faDiagramProject, faDownload, faEarthAmericas, faEye, faFileInvoice, faFilePen, faForwardStep, faHomeUser, faHourglassHalf, faImage, faListCheck, faLocationDot, faLocationPin, faPhone, faSignOutAlt, faStreetView, faThumbTack, faUpload, faUserCheck, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

library.add(faArrowUpRightFromSquare)
library.add(faThumbTack)
library.add(faFilePdf);
library.add(faSearch);
library.add(faEllipsisH);
library.add(faCalendarAlt);
library.add(faForwardStep);
library.add(faFileCircleCheck);
library.add(faTriangleExclamation);
library.add(faLocationPin);
library.add(faImage);
library.add(faUpload);
library.add(faChartSimple);
library.add(faPhone);
library.add(faPlus);
library.add(faKey);
library.add(faHourglassHalf);
library.add(faCoins);
library.add(faStreetView);
library.add(faAddressCard);
library.add(faArrowLeft);
library.add(faEnvelope);
library.add(faEnvelopeOpenText);
library.add(faBuilding);
library.add(faPaperPlane);
library.add(faXmark);
library.add(faForward);
library.add(faMessage);
library.add(faTrash);
library.add(faUsers);
library.add(faUser);
library.add(faSignOutAlt);
library.add(faEarthAmericas);
library.add(faFileInvoice);
library.add(faCheckCircle);
library.add(faCalendarDays);
library.add(faCalendarCheck);
library.add(faArchive);
library.add(faLocationDot);
library.add(faUserGroup);
library.add(faHomeUser);
library.add(faFileInvoice);
library.add(faDiagramProject);
library.add(faBars);
library.add(faDownload);
library.add(faEye);
library.add(faFilePen);
library.add(faArrowsUpToLine)
library.add(faListCheck)
library.add(faShareFromSquare)
library.add(faMessage)


let app=createApp(App)
app.component("font-awesome-icon", FontAwesomeIcon).use(router).mount("#app");