const { default: Encryption } = require("@/Encryption")

function authPUT(path, content, callback) {
    authChange(path, "PUT", content, callback)
}

function authPOST(path, content, callback) {
    authChange(path, "POST", content, callback)
}

function authDelete(path, callback) {
    authChange(path, "DELETE", {}, callback)
}

function loginWithCode(provider, code, redirectOrMail, callback) {
    var req = new XMLHttpRequest()
    req.open("POST", process.env.VUE_APP_BUSINESS_URL + '/auth/login')
    req.setRequestHeader('content-type', 'application/json')
    req.addEventListener('load', function (event) {
        try {
            callback(JSON.parse(req.responseText))
        } catch (e) {
            callback(false)
        }
    })
    req.send(JSON.stringify({
        provider: provider,
        code: code,
        redirectOrMail: redirectOrMail
    }))
}


function authChange(path, method, content, callback) {
    var req = new XMLHttpRequest()
    req.open(method, process.env.VUE_APP_BUSINESS_URL + path)
    req.setRequestHeader('content-type', 'application/json')
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        try {
            json = JSON.parse(req.responseText)
            callback(JSON.parse(req.responseText))
        } catch (e) {
            console.log(e)
            callback(false)
        }
    })
    req.send(JSON.stringify(content))
}

function authGET(path, callback) {
    var req = new XMLHttpRequest()
    req.open('GET', process.env.VUE_APP_BUSINESS_URL + path)
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        if (req.status == 204) {
            callback(true)
        } else {
            try {
                callback(JSON.parse(req.responseText))
            } catch (e) {
                console.log(e)
                callback(false)
            }
        }
    })
    req.send()
}

function getJobs(callback) {
    authGET("/jobs", callback)
}
function getArchivedJobs(callback) {
    authGET("/jobs?archived=true", callback)
}

function getJob(jobId, callback) {
    authGET("/jobs/" + jobId, callback)
}

function addJob(job, callback) {
    authPOST("/jobs", job, callback)
}

function updateJob(job, callback) {
    authPUT("/jobs/" + job.id, job, callback)
}
function updateTenant(tenant, callback) {
    authPUT("/tenant/" + tenant.id, tenant, callback)
}
function updateTenantById(id, changes, callback) {
    authPUT("/tenant/" + id, changes, callback)
}

function addApplication(application, key, callback) {
    authPOST("/applications", Encryption.encryptApplication(application, key), callback)
}

function updateApplication(changes, key, id, callback) {
    authPUT("/applications/" + id, Encryption.encryptApplication(changes, key), (application) => {
        callback(Encryption.decryptApplication(application, key))
    })
}

function updateUser(changes, callback) {
    authPUT("/user", changes, callback)
}

function updateApplicationOrder(job, applicationOrder) {
    authPUT("/applications/order", {job: job.id, order: applicationOrder}, () => { })
}

function updateJobOrder(jobOrder) {
    authPUT("/jobs/order", jobOrder, () => { })
}

function getApplicationWithId(id, key, callback) {
    authGET('/applications/' + id, (application) => {
        callback(Encryption.decryptApplication(application, key))
    })
}

function getApplicationsForJob(job, key, callback) {
    authGET('/applications?job=' + job.id, (applications) => {
        applications.forEach((p, i, a) => {
            a[i] = Encryption.decryptApplication(p, key)
        });
        callback(applications);
    })
}

function getArchivedApplications(key, callback) {
    authGET('/applications?archived', (applications) => {
        applications.forEach((p, i, a) => {
            a[i] = Encryption.decryptApplication(p, key)
        });
        callback(applications);
    })
}

function getCommentsForApplication(application, key, callback) {
    authGET('/comments?application=' + application.id, (comments) => {
        comments.forEach((p, i, a) => {
            a[i] = Encryption.decryptComment(p, key)
        });
        callback(comments);
    })
}

function addComment(comment, key, callback) {
    authPOST("/comments", Encryption.encryptComment(comment, key), callback)
}
function pinComment(comment, callback) {
    authPUT("/comments/" + comment.id, {
        pinned: !comment.pinned
    }, callback)
}

function setPermissions(permissions, callback) {
    authPOST("/jobpermissions", permissions, callback)
}

function addFile(file, key, callback) {
    authPOST("/files", Encryption.encryptFile(file, key), callback)
}

function updateFile(id, changes, key, callback) {
    authPUT("/files/"+id, Encryption.encryptFile(changes, key), callback)
}

function addTenant(tenant, callback) {
    authPOST("/tenant", tenant, callback)
}

function checkTenantSlug(slug, callback) {
    authGET("/tenant/slug/" + slug, callback)
}

function deleteTenantRole(tenant, role, callback) {
    authDelete("/tenant/" + tenant + "/role/" + role.id, callback)
}

function deleteApplication(applicationId, callback) {
    authDelete("/applications/" + applicationId, callback)
}

function addTenantRole(tenant, role, callback) {
    authPOST("/tenant/" + tenant + "/role", role, callback)
}

function updateTenantRole(tenant, role, callback) {
    authPUT("/tenant/" + tenant + "/role/" + role.id, role, callback)
}

function saveDefaults(defaults, callback) {
    authPOST("/defaults", defaults, callback)
}

function getFile(fileId, key, callback) {
    authGET("/files/" + fileId, (file) => {
        callback(Encryption.decryptFile(file, key))
    })
}

function deleteFile(file, callback) {
    authDelete("/files/" + file.id, callback)
}

function getUser(callback) {
    authGET("/user", callback)
}

function getTenant(callback) {
    authGET("/tenant", callback)
}
function getTemplates(callback) {
    authGET("/templates", callback)
}

function getSearchEntries(callback) {
    authGET("/search", callback)
}

function editTemplates(templates, callback) {
    authPOST("/templates", templates, callback)
}
function addLogo(logo, callback) {
    authPOST("/logo", { logo: logo }, callback)
}
function deleteLogo(callback) {
    authDelete("/logo", callback)
}
function getLogo(slug,callback) {
    authGET("/logo?slug="+slug, callback)
}
function testMailboxConnection(options, cb){
    authPOST("/tenant/mailbox/test", options, cb)
}

function getTeammembers(jobId, cb){
    authGET("/jobs/" + jobId + "/teammembers", cb)
}

function addChatMessage(applicationId, message, cb){
    authPOST("/applications/" + applicationId + "/chat", message, cb)
}

function getChatMessages(applicationId, cb){
    authGET("/applications/" + applicationId + "/chat", cb)
}

function getChats(cb){
    authGET("/chats", cb)
}

module.exports = {
    getChats: getChats,
    addChatMessage: addChatMessage,
    getChatMessages: getChatMessages,
    getLogo: getLogo,
    addLogo: addLogo,
    deleteLogo: deleteLogo,
    loginWithCode: loginWithCode,
    getJobs: getJobs,
    getArchivedJobs: getArchivedJobs,
    getJob: getJob,
    getTenant: getTenant,
    getArchivedApplications: getArchivedApplications,
    getApplicationsForJob: getApplicationsForJob,
    getSearchEntries: getSearchEntries,
    addJob: addJob,
    updateJob: updateJob,
    addApplication: addApplication,
    updateApplication: updateApplication,
    updateUser: updateUser,
    updateApplicationOrder: updateApplicationOrder,
    getCommentsForApplication: getCommentsForApplication,
    addComment: addComment,
    setPermissions: setPermissions,
    addFile: addFile,
    addTenant: addTenant,
    addTenantRole: addTenantRole,
    deleteTenantRole: deleteTenantRole,
    deleteApplication: deleteApplication,
    updateTenantRole: updateTenantRole,
    updateTenant: updateTenant,
    updateTenantById: updateTenantById,
    getFile: getFile,
    deleteFile: deleteFile,
    getApplicationWithId: getApplicationWithId,
    getTemplates: getTemplates,
    editTemplates: editTemplates,
    saveDefaults: saveDefaults,
    getUser: getUser,
    checkTenantSlug: checkTenantSlug,
    pinComment: pinComment,
    testMailboxConnection: testMailboxConnection,
    updateFile: updateFile,
    updateJobOrder: updateJobOrder,
    getTeammembers: getTeammembers
}