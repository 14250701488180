<template>
  <div class="w-full relative p-4 overflow-y-auto">
    <span class="font-semibold text-2xl text-gray-700">Nachrichten</span><br>
    <span v-if="chats.length == 0" class="font-semibold text-xl text-gray-700 mt-5">Du hast keine offenen Chats.</span><br>
    <span v-if="chats.length == 0 && context=='business'">Du kannst nur mit Bewerbern chatten, die sich direkt über Applyhr beworben haben.</span>
    <ChatTile v-for="chat in chats" :key="chat.id" :chat="chat" :context="context"/>
  </div>
</template>

<script>
// @ is an alias to /src
import { useCookies } from "vue3-cookies";
import BusinessAPI from "@/API/Business";
import PrivateAPI from "@/API/Private";
import ChatTile from "@/components/tiles/ChatTile.vue";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: {
    ChatTile
  },
  name: "ChatView",
  props: {
    context: String,
    user: Object,
  },
  data() {
    return {
      chats: [],
      API: BusinessAPI
    };
  },
  methods: {},
  mounted() {
    let vm = this;
    if(this.context=="private"){
      this.API=PrivateAPI
    }
    this.API.getChats((s) => {
      //if (s) vm.chats = s;
    });
  },
};
</script>
<style scoped>
input {
  border-bottom: 1px solid black;
}
</style>