<template>
  <div
    ref="modalbg"
    class="z-10 center w-full h-full bg-black/50 text-center md:pt-5 md:pb-5"
    @click="checkClosing($event)"
  >
    <AddFileModal
      v-if="showAddFileModal"
      :kind="newFileKind"
      :files="files"
      @closeModal="showAddFileModal = false"
      @addFile="addFile"
    />
    <div
      ref="modalcontent"
      class="z-20 lg:w-2/3 bg-white lg:rounded-3xl p-8 inline-block text-left relative max-h-full overflow-y-scroll break-all pb-10"
    >
      <div class="w-full overflow-y-auto inline-block">
        <div @click="$emit('closeJobDetails')" class="iconborder-modal z-40">
          <font-awesome-icon size="lg" icon="xmark" class="inborder" />
        </div>
        <div class="w-full rounded overflow-y-auto">
          <div class="bg-white pb-5 w-full text-center z-30">
            <span v-if="job" class="font-bold text-3xl">{{ job.title }}</span>
            <div v-else>
              <span class="font-bold text-3xl">Neue Bewerbung</span><br />
            </div>
          </div>
          <div id="content" class="mb-5">
            <div>
              <div v-if="targetDate" class="grid grid-cols-2">
                <span class="block font-semibold text-lg">Verfügbar ab:</span>
                <span class="block font-semibold text-lg">{{
                  targetDate
                }}</span>
              </div>
              <div v-if="job.weekhours" class="grid grid-cols-2">
                <span class="block font-semibold text-lg">Wochenstunden:</span>
                <span class="block font-semibold text-lg">{{
                  job.weekhours
                }}</span>
              </div>
              <div v-if="job.location" class="grid grid-cols-2">
                <span class="block font-semibold text-lg">Ort:</span>
                <span class="block font-semibold text-lg">{{
                  job.location
                }}</span>
              </div>
              <div v-if="job.department" class="grid grid-cols-2">
                <span class="block font-semibold text-lg">Abteilung:</span>
                <span class="block font-semibold text-lg">{{
                  job.department
                }}</span>
              </div>
              <div class="grid grid-cols-2">
                <span class="block font-semibold text-lg"
                  >Homeoffice möglich:</span
                >
                <span v-if="job.homeoffice" class="block font-semibold text-lg"
                  >Ja</span
                >
                <span v-else class="block font-semibold text-lg">Nein</span>
              </div>
              <a
                target="_blank"
                v-if="job.linktype != 'applyhr' && job.link.startsWith('http')"
                :href="job.link"
                class="underline text-lg align-middle text-black font-semibold col-span-2"
                >{{ portal }}</a
              >
              <div class="">
                <div class="mt-2 relative" v-if="job.tasks">
                  <span class="font-bold text-xl">Aufgaben:</span><br />
                  <pre
                    class="font-normal text-black whitespace-pre-line break-normal"
                    >{{ job.tasks }}</pre
                  >
                </div>
                <div class="mt-2" v-if="job.profile">
                  <span class="font-bold text-xl">Profil:</span><br />
                  <pre
                    class="font-normal text-black whitespace-pre-line break-normal"
                    >{{ job.profile }}</pre
                  >
                </div>
                <div class="mt-2" v-if="job.benefits">
                  <span class="font-bold text-xl">Das bieten wir:</span><br />
                  <pre
                    class="font-normal text-black whitespace-pre-line break-normal"
                    >{{ job.benefits }}</pre
                  >
                </div>
              </div>
            </div>

            <div v-if="!showApplication" class="relative">
              <span class="font-bold text-2xl">Bewerbung</span><br />
              <span
                >Bewirb dich direkt hier. Lade dazu lediglich die erforderlichen
                Dokumente hoch und ergänze die folgenden Daten.</span
              >
              <div class="w-full text-center">
                <button
                  @click="startApplication()"
                  class="mt-5 inline-block text-lg"
                >
                  <img
                    src="@/static/LogoColored.png"
                    class="h-10 inline-block"
                  />Jetzt über
                  <span class="font-bold mt-3 text-lg text-secondary"
                    >Apply</span
                  >
                  <span class="font-bold mt-3 text-lg text-primary">hr</span>
                  bewerben
                </button><br>
                <button class="inline-block mt-4" v-if="job.offerMail" @click="useMail()"> 
                  Per E-Mail bewerben
                  </button>
              </div>
            </div>
            <div id="apply" class="overflow-y-auto pt-4">
              <div class="w-full text-center"></div>
              <div v-if="showApplication || !job">
                <span class="inline-block md:hidden text-2xl font-bold pb-4"
                  >Deine Daten</span
                >
                <div class="w-full">
                  <span
                    v-if="errors.name"
                    class="font-semibold text-xl text-red-500"
                    >Vor- und Nachname (Pflichtfeld)</span
                  >
                  <span v-else class="font-semibold text-xl"
                    >Vor- und Nachname</span
                  >
                  <input
                    class="w-full bg-gray-100 p-3 mt-2"
                    placeholder="Vor- und Nachname"
                    v-model="application.name"
                  />
                </div>
                <div class="grid md:grid-cols-2">
                  <div class="pt-6 pr-6">
                    <span
                      v-if="errors.mail"
                      class="font-semibold text-xl text-red-500"
                      >E-Mail (Pflichtfeld)</span
                    >
                    <span v-else class="block font-semibold text-xl"
                      >E-Mail</span
                    >
                    <input
                      class="w-full bg-gray-100 p-3 mt-2"
                      placeholder="E-Mail"
                      v-model="application.mail"
                    />
                  </div>
                  <div class="pt-6" v-if="!job || job.requiredFields.phone">
                    <span
                      v-if="errors.phone"
                      class="font-semibold text-xl text-red-500"
                      >Telefonnummer (Pflichtfeld)</span
                    >
                    <span v-else class="block font-semibold text-xl"
                      >Telefonnummer</span
                    >
                    <input
                      class="w-full bg-gray-100 p-3 mt-2"
                      placeholder="Telefonnummer"
                      v-model="application.phone"
                    />
                  </div>
                </div>
                <div class="grid md:grid-cols-2">
                  <div
                    v-if="!job || job.requiredFields.startDate"
                    class="pt-6 pr-6"
                  >
                    <span
                      v-if="errors.startDate"
                      class="font-semibold text-xl text-red-500"
                      >Frühester Start (Pflichtfeld)</span
                    >
                    <span v-else class="block font-semibold text-xl"
                      >Frühester Start</span
                    >
                    <input
                      type="date"
                      class="w-full bg-gray-100 p-3 mt-2"
                      placeholder="Link zum Bewerberportal"
                      v-model="application.startDate"
                    />
                  </div>
                  <div v-if="!job || job.requiredFields.salary" class="pt-6">
                    <span
                      v-if="errors.salary"
                      class="block font-semibold text-xl text-red-500"
                      >Gehaltsvorstellungen (Pflichtfeld)</span
                    >
                    <span v-else class="block font-semibold text-xl"
                      >Gehaltsvorstellungen</span
                    >
                    <input
                      class="w-5/12 bg-gray-100 p-3 mt-2 text-center"
                      placeholder="35000"
                      v-model="application.salary"
                    />
                    <b>€</b>
                  </div>
                  <div v-if="!job || job.requiredFields.weekhours" class="pt-6">
                    <span
                      v-if="errors.weekhours"
                      class="font-semibold text-xl text-red-500"
                      >Wochenstunden (Pflichtfeld)</span
                    >
                    <span v-else class="block font-semibold text-xl"
                      >Wochenstunden</span
                    >
                    <input
                      class="w-3/12 bg-gray-100 p-3 mt-2 text-center"
                      placeholder="40"
                      type="number"
                      v-model="application.weekhours"
                    />
                    <b>h/Woche</b>
                  </div>
                  <div v-if="!job || job.requiredFields.city" class="pt-6">
                    <span
                      v-if="errors.city"
                      class="font-semibold text-xl text-red-500"
                      >Wohnort (Pflichtfeld)</span
                    >
                    <span v-else class="block font-semibold text-xl"
                      >Wohnort</span
                    >
                    <input
                      class="w-full bg-gray-100 p-3 mt-2 text-left"
                      placeholder="Köln"
                      v-model="application.city"
                    />
                  </div>
                  <div v-if="!job || job.requiredFields.birthdate" class="pt-6">
                    <span
                      v-if="errors.birthdate"
                      class="font-semibold text-xl text-red-500"
                      >Geburtsdatum (Pflichtfeld)</span
                    >
                    <span v-else class="block font-semibold text-xl"
                      >Geburtsdatum</span
                    >
                    <input
                      class="w-full bg-gray-100 p-3 mt-2 text-left"
                      type="date"
                      v-model="application.birthdate"
                    />
                  </div>
                </div>
                <div class="pt-6">
                  <span class="font-semibold text-xl">Dokumente hochladen</span>
                  <div class="overflow-y-auto max-h-32 mt-2">
                    <div
                      v-for="file in application.files"
                      :key="file.id"
                      class="mt-2 relative"
                    >
                      <div class="w-full overflow-hidden">
                        <span
                          class="border-b-2 border-secondary cursor-pointer"
                          @click="getFile(file)"
                          >{{ file.originalName }}</span
                        >
                        <div class="float-right">
                          <span class="text-secondary">{{
                            file.documentType
                          }}</span>

                          <font-awesome-icon
                            icon="trash"
                            @click="deleteFile(file)"
                            class="pr-1 px-2 text-secondary cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    id="newApplicationFileUpload"
                    @change="prepareUploadFile()"
                    hidden
                  />
                  <div class="grid md:grid-cols-2">
                    <div
                      v-if="
                        job.requiredFields.cover &&
                        application.files.filter((entry) => {
                          return entry.documentType == 'Anschreiben';
                        }).length == 0
                      "
                      class="inline-block cursor-pointer mt-3"
                      @click="showFilePicker('Anschreiben')"
                    >
                      <div
                        class="iconborder-p inline-block"
                        style="float: unset"
                      >
                        <font-awesome-icon icon="plus" class="inborder" />
                      </div>
                      <span
                        v-if="errors.cover"
                        class="ml-3 align-middle font-semibold text-red-500"
                        >Anschreiben (Pflicht)</span
                      >
                      <span
                        v-else
                        class="ml-3 align-middle text-secondary font-semibold"
                        >Anschreiben</span
                      >
                    </div>
                    <div
                      v-if="
                        job.requiredFields.cv &&
                        application.files.filter((entry) => {
                          return entry.documentType == 'Lebenslauf';
                        }).length == 0
                      "
                      class="inline-block cursor-pointer mt-3 w-"
                      @click="showFilePicker('Lebenslauf')"
                    >
                      <div
                        class="iconborder-p inline-block"
                        style="float: unset"
                      >
                        <font-awesome-icon icon="plus" class="inborder" />
                      </div>
                      <span
                        v-if="errors.cv"
                        class="ml-3 align-middle font-semibold text-red-500"
                        >Lebenslauf (Pflicht)</span
                      >
                      <span
                        v-else
                        class="ml-3 align-middle text-secondary font-semibold"
                        >Lebenslauf</span
                      >
                    </div>
                    <div
                      v-if="
                        job.requiredFields.qualifications &&
                        application.files.filter((entry) => {
                          return entry.documentType == 'Qualifikation';
                        }).length == 0
                      "
                      class="inline-block cursor-pointer mt-3"
                      @click="showFilePicker('Qualifikation')"
                    >
                      <div
                        class="iconborder-p inline-block"
                        style="float: unset"
                      >
                        <font-awesome-icon icon="plus" class="inborder" />
                      </div>
                      <span
                        v-if="errors.qualifications"
                        class="ml-3 align-middle font-semibold text-red-500"
                        >Qualifikationen (Pflicht)</span
                      >
                      <span
                        v-else
                        class="ml-3 align-middle text-secondary font-semibold"
                        >Qualifikationen</span
                      >
                    </div>
                    <div
                      class="inline-block cursor-pointer mt-3"
                      @click="showFilePicker('Sonstiges')"
                    >
                      <div
                        class="iconborder-p inline-block"
                        style="float: unset"
                      >
                        <font-awesome-icon icon="plus" class="inborder" />
                      </div>
                      <span
                        class="ml-3 align-middle text-secondary font-semibold"
                        >Sonstiges Dokument</span
                      >
                      (optional)
                    </div>
                  </div>
                  <div class="mt-5" v-if="!job">
                    <span class="font-semibold text-xl">Kommentar</span>
                    <textarea
                      v-model="application.comment"
                      class="w-full mt-2 h-24 overflow-y-auto bg-gray-100 p-2 resize-none"
                      placeholder="Schreib einen Kommentar..."
                    ></textarea>
                  </div>
                </div>
                <div class="mt-5 w-full text-center">
                  <span class="text-red-500 font-bold"
                    >Alle Angaben sind Pflichtangaben.</span
                  ><br />
                  <button
                    @click="submitApplication()"
                    class="relative mx-2 font-bold text-xl border-2"
                  >
                    <div
                      v-if="loading"
                      class="lds-dual-ring overflow-hidden"
                    ></div>
                    <span
                      v-if="job"
                      v-bind:class="[loading ? 'text-white' : '']"
                      >Bewerben</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API/Private";
import { useCookies } from "vue3-cookies";
import AddFileModal from "../AddFileModal.vue";

const documentmap = {
  cover: "Anschreiben",
  cv: "Lebenslauf",
  qualifications: "Qualifikation",
};
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: { AddFileModal },
  expose: ["someMethod"],
  emits: ["closeJobDetails", "applicationSaved"],
  props: {
    job: Object,
    user: Object,
  },
  data() {
    return {
      waitFor: 0,
      showApplication: false,
      application: {
        weekhours: this.job ? this.job.weekhours : 40,
        mail: this.user.mail,
        name: this.user.name,
        phone: this.user.phone,
        city: this.user.city,
        birthdate: this.user.birthdate,
        files: [],
        encrypted: false,
        comment: "",
      },
      currentUploadFileType: "",
      jobtitlemissing: false,
      loading: false,
      files: {},
      errors: {},
      showAddFileModal: false,
      newFileKind: "Anschreiben",
    };
  },
  methods: {
    checkClosing(event) {
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeJobDetails");
      }
    },
    startApplication() {
      if (this.user.mail) {
        this.showApplication = true;
      } else {
        this.cookies.set(
          "redirect",
          this.$route.path.trim() + "?j=" + this.job.id
        );
        window.location = "/login?mode=private";
      }
    },
    submitApplication() {
      this.loading = true;
      this.errors = {};
      let vm = this;
      if (!this.application["name"]) this.errors["name"] = true;
      Object.keys(this.job.requiredFields).forEach((requiredField) => {
        if (!this.job.requiredFields[requiredField]) return;
        if (["cover", "cv", "qualifications"].includes(requiredField)) {
          if (
            this.application.files.filter((entry) => {
              return entry.documentType == documentmap[requiredField];
            }).length == 0
          )
            this.errors[requiredField] = true;
        } else {
          if (!this.application[requiredField])
            this.errors[requiredField] = true;
        }
      });
      if (Object.keys(this.errors).length > 0) {
        this.loading = false;
        return;
      }
      if (this.job) {
        this.application.job = this.job.id;
      } else if (this.application.jobtitle == undefined) {
        this.jobtitlemissing = true;
        return;
      }
      API.addApplication(this.application, (application) => {
        if (application) {
          vm.$emit("applicationSaved");
        } else {
        }
      });
    },
    useMail() {
      window.open("mailto:" + this.job.contact_mail+"?subject=Ihre Stelle \""+this.job.title+"\"", "_blank");
    },
    showFilePicker(filetype) {
      this.newFileKind = filetype;
      this.showAddFileModal = true;
    },
    addFile(file) {
      this.showAddFileModal = false;
      this.application.files.push(file);
    },
    deleteFile(file) {
      this.application.files.splice(this.application.files.indexOf(file), 1);
    },
    taskDone() {
      if (this.waitFor <= 1) {
        this.$emit("applicationSaved");
      } else {
        this.waitFor--;
      }
    },
  },
  computed: {
    portal: function () {
      if (this.job.link.startsWith("http")) {
        if (this.job.link.match(/\.([a-z-]*)\.[a-z]*\//)) {
          let portal = this.job.link.match(/\.([a-z-]*)\.[a-z]*\//)[1];
          return portal.charAt(0).toUpperCase() + portal.slice(1);
        }
      }
      return "-";
    },
    targetDate: function () {
      let es = Date.parse(this.job.targetDate + "T00:00");
      return isNaN(es)
        ? "Sofort"
        : new Date(es).toLocaleString("de-DE").split(",")[0];
    },
  },
  created: function () {
    let vm = this;
    API.getFiles((files) => {
      vm.files = files;
      if (this.user.mail) {
        this.showApplication = true;
      }
    });
  },
};
</script>

<style scoped>
</style>
