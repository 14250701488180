<template>
  <div class="w-full h-full relative flex flex-col p-4 md:grow min-w-0">
    <Transition>
      <top-banner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
    </Transition>
    <Transition>
      <ChatWindow
        v-if="chatApplication"
        context="private"
        :application="chatApplication"
        @close="chatApplication = false"
        @openApplication="showDetails(chatApplication,true)"
      />
    </Transition>
    <file-viewer
      v-if="viewFile"
      :file="currentFile"
      @closeFileViewer="viewFile = false"
      class="absolute pb-10 mr-4 h-11/12 z-40"
    />
    <div class="relative h-full">
      <div class="inline-block w-full">
        <span class="font-semibold text-2xl text-gray-700"
          >Deine Bewerbungen</span
        >
      </div>
      <div class="h-full overflow-y-scroll pb-10 lg:overflow-none">
        <div class="pt-4 grid lg:grid-cols-3 grid-cols-1 lg:h-full">
          <div class="pt-4 flex flex-col overflow-y-hidden">
            <div class="inline-block w-full">
              <span class="text-xl font-semibold text-secondary pl-3"
                >Verschickt / Absage</span
              >
              <div
                class="float-right px-3 cursor-pointer align-middle mt-1"
                @click="addApplication = true"
              >
                <span class="text-primary mx-3 lg:hidden xl:inline"
                  >Hinzufügen</span
                >
                <div class="iconborder align-middle">
                  <font-awesome-icon icon="plus" class="inborder" />
                </div>
              </div>
            </div>
            <div
              v-if="applications.sent.length == 0"
              class="cursor-pointer scale-100 inline-block w-full rounded-xl relative shadow-selected mt-2 bg-white p-2"
              @click="addApplication = true"
            >
              <div class="w-full h-full align-middle text-center">
                <span class="w-full text-center text-lg font-bold"
                  >Bewerbung hinzufügen</span
                ><br />
                <font-awesome-icon
                  icon="plus"
                  class="mt-1 inline-block text-5xl"
                />
              </div>
            </div>
            <draggable
              v-model="applications.sent"
              group="applications"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
              class="h-full overflow-y-auto hidden sm:inline-block"
              @change="changeStatus('Verschickt', $event)"
            >
              <template #item="{ element }">
                <PrivateJobTile
                  @openChat="chatApplication = element"
                  @click="showDetails(element)"
                  :application="element"
                  :currentApplication="currentApplication"
                />
              </template>
            </draggable>
            <div class="h-full overflow-y-auto sm:hidden inline-block">
              <PrivateJobTile
                @openChat="chatApplication = element"
                v-for="element in applications.sent"
                :key="element.id"
                @click="showDetails(element)"
                :application="element"
                :currentApplication="currentApplication"
              />
            </div>
          </div>
          <div class="pt-4 flex flex-col overflow-y-hidden">
            <div class="inline-block w-full">
              <span class="text-xl font-semibold text-secondary pl-3"
                >Vorstellungsgespräch</span
              >
            </div>
            <draggable
              v-model="applications.interview"
              group="applications"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
              class="h-full overflow-y-auto hidden sm:inline-block"
              @change="changeStatus('Interview', $event)"
            >
              <template #item="{ element }">
                <PrivateJobTile
                  @openChat="chatApplication = element"
                  @click="showDetails(element)"
                  :application="element"
                  :currentApplication="currentApplication"
                />
              </template>
            </draggable>
            <div class="h-full overflow-y-auto sm:hidden inline-block">
              <PrivateJobTile
                @openChat="chatApplication = element"
                v-for="element in applications.interview"
                :key="element.id"
                @click="showDetails(element)"
                :application="element"
                :currentApplication="currentApplication"
              />
            </div>
          </div>
          <div class="pt-4 flex flex-col overflow-y-hidden">
            <div class="inline-block w-full pl-3">
              <span class="text-xl font-semibold text-secondary">Angebot</span>
            </div>
            <draggable
              v-model="applications.offer"
              group="applications"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
              class="h-full overflow-y-auto hidden sm:inline-block"
              @change="changeStatus('Angebot', $event)"
            >
              <template #item="{ element }">
                <PrivateJobTile
                  @click="showDetails(element)"
                  :application="element"
                  :currentApplication="currentApplication"
                />
              </template>
            </draggable>
            <div class="h-full overflow-y-auto sm:hidden inline-block">
              <PrivateJobTile
                v-for="element in applications.offer"
                :key="element.id"
                @click="showDetails(element)"
                :application="element"
                :currentApplication="currentApplication"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PrivateApplicationModal
      v-if="addApplication && user"
      :user="user"
      @applicationSaved="getApplications()"
      @closeJobDetails="addApplication = false"
    />
  </div>
  <private-details-sidebar
    ref="detailbar"
    :application="currentApplication"
    v-if="detailsVisible"
    @closeDetails="detailsVisible = false"
    @deleteApplication="deleteApplication"
    @fileReceived="fileReceived"
    @applicationUpdated="applicationUpdated"
  />
</template>

<script>
import draggable from "vuedraggable";
import TopBanner from "@/components/TopBanner.vue";
import FileViewer from "@/components/FileViewer.vue";
import PrivateJobTile from "@/components/tiles/PrivateApplicationTile.vue";
import ChatWindow from "@/components/shared/ChatWindow.vue";

import API from "@/API/Private";
import PrivateApplicationModal from "@/components/private/PrivateApplicationModal.vue";
import PrivateDetailsSidebar from "@/components/private/PrivateDetailsSidebar.vue";
// @ is an alias to /src

export default {
  name: "TenantJobsView",
  components: {
    TopBanner,
    FileViewer,
    ChatWindow,
    PrivateJobTile,
    draggable,
    PrivateApplicationModal,
    PrivateDetailsSidebar,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      detailsVisible: false,
      addApplication: false,
      chatApplication: false,

      viewFile: false,
      currentFile: false,
      bannerText: "",
      currentApplication: { job: {} },
      applications: {
        sent: [],
        interview: [],
        offer: [],
      },
    };
  },

  methods: {
    changeStatus(status, e) {
      if (e.added != undefined) {
        e.added.element.status = status;
        API.updateApplication(e.added.element.id, e.added.element, () => {});
        this.persistOrder();
      } else if (e.moved != undefined) {
        this.persistOrder();
      }
    },
    fileReceived(file) {
      this.currentFile = file;
      this.viewFile = true;
    },
    deleteApplication(id) {
      API.deleteApplication(id, (success) => {
        if (success) {
          this.detailsVisible = false;
          this.currentApplication = { job: {} };
          this.getApplications();
        }
      });
    },
    persistOrder() {
      let order = [];
      let i = 0;
      this.applications.sent.forEach((application) => {
        let a = {};
        a.id = application.id;
        a.order = i;
        i = i + 1;
        order.push(a);
      });
      this.applications.interview.forEach((application) => {
        let a = {};
        a.id = application.id;
        a.order = i;
        i++;
        order.push(a);
      });
      this.applications.offer.forEach((application) => {
        let a = {};
        a.id = application.id;
        a.order = i;
        i++;
        order.push(a);
      });
      API.updateApplicationOrder(order, () => {});
    },
    showDetails(element, evenIfChat=false) {
      if(evenIfChat){
        this.chatApplication=false;
      }
      if (this.chatApplication) return;
      this.currentApplication = element;
      this.detailsVisible = true;
    },
    applicationUpdated(type = "update") {
      this.getApplications(this.currentApplication.id);
    },
    getApplications(keepId = false) {
      this.addApplication = false;
      let vm = this;
      //vm.detailsVisible = false;
      API.getApplications((applications) => {
        applications.forEach((app) => {
          app.link = app.weblink;
          app.title = app.jobtitle;
        });
        vm.applications.sent = applications.filter(
          (item) => item.status === "Verschickt" || item.status === "Abgesagt"
        );
        vm.applications.interview = applications.filter(
          (item) => item.status === "Interview"
        );
        vm.applications.offer = applications.filter(
          (item) => item.status === "Angebot"
        );
        vm.detailsVisible = false;
        if (keepId || vm.$route.query.a) {
          let id = keepId ? keepId : vm.$route.query.a;
          delete vm.$route.query.a;
          history.pushState({}, null, "/c/overview");
          vm.currentApplication = applications.filter(
            (item) => item.id == id
          )[0];
          vm.detailsVisible = true;
        } else if (vm.$route.query.c) {
          vm.chatApplication = applications.filter(
            (item) => item.id == vm.$route.query.c
          )[0];
          delete vm.$route.query.a;
          history.pushState({}, null, "/c/overview");
        }
      });
    },
  },
  created: function () {
    this.getApplications();
  },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 4s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}
</style>